import * as Yup from 'yup';
import VALIDATION_MESSAGES from 'constants/validationMessages';
import {
  callTermTextMaxLength,
  compTermNoteMaxLength,
  minDecimalValue,
  minNumericValue,
  paidTimeOffMaxLength,
} from 'constants/fieldsLimits';
import { IClinician } from 'interfaces/Clinician/IClinician';

export const clinicianContractValidationSchema = (clinicians: IClinician[]) => {
  return Yup.object({
    clinician_id: Yup
      .number()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .test(
        'is valid',
        VALIDATION_MESSAGES.CLINICIAN_NO_TITLE,
        (value) => (clinicians.find((clinician: IClinician) => clinician.id === value)?.title == null ? false : true),
      ),
    facility_id: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
    contract_type: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    compensation_grid_name: Yup.string().required(VALIDATION_MESSAGES.COMP_GRID_NOT_EXISTS),
    contract_end_type: Yup
      .string()
      .nullable()
      .optional()
      .max(callTermTextMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(callTermTextMaxLength)),
    resign_date: Yup.date()
      .nullable()
      .optional()
      .typeError('Invalid Date'),
    last_day_of_service: Yup.date()
      .nullable()
      .optional()
      .typeError('Invalid Date'),
    payroll_details: Yup.object({
      engagement: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
      contract_term: Yup.number().nullable().min(minNumericValue, VALIDATION_MESSAGES.POSITIVE),
      weeks_of_service: Yup.number().nullable().min(minNumericValue, VALIDATION_MESSAGES.POSITIVE),
      paid_time_off: Yup
        .string()
        .nullable()
        .optional()
        .max(paidTimeOffMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(paidTimeOffMaxLength)),
      fte: Yup.number().min(minDecimalValue, VALIDATION_MESSAGES.MIN_NUMBER(minDecimalValue)).when('engagement', {
        is: (val: string) => val === 'part_time',
        then: (scheme: any) => scheme.required(VALIDATION_MESSAGES.REQUIRED),
      }),
      execution_date: Yup.date()
        .nullable()
        .optional()
        .typeError('Invalid Date'),
      effective_date: Yup.date()
        .required(VALIDATION_MESSAGES.REQUIRED)
        .typeError('Invalid Date'),
      contracted_hours: Yup
        .string()
        .nullable()
        .optional()
        .max(callTermTextMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(callTermTextMaxLength)),
      notes: Yup.string().nullable().optional().max(compTermNoteMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(compTermNoteMaxLength)),
      malpractice_deduction: Yup.string()
        .nullable().optional().max(callTermTextMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(callTermTextMaxLength)),
    }),
  });
};
