export const stringMaxLength = 100;
export const addressMaxLength = 45;
export const acronymMaxLength = 20;
export const bankAccountNumberMaxLength = 40;
export const payrollIdMaxLength = 50;
export const shortStringMaxLength = 10;
export const maxIntegerValue = 2147483647;
export const minNumericValue = 1;
export const minDecimalValue = 0.01;

export const compTermMaxLength = 500;
export const compTermNoteMaxLength = 1000;

export const callTermTextMaxLength = 255;

export const paidTimeOffMaxLength = 50;

export const phoneRegex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
export const ssnRegex = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;
export const accountRoutingNumberRegex = /^[0-9]{3} [0-9]{3} [0-9]{3}$/;

export const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,})+$/;
