import dayjs from 'dayjs';
import { findLast, compact } from 'lodash';

import { IClinicianContractForm } from 'interfaces/ClinicianContract/IClinicianContractForm';
import { ICompensationRate } from 'interfaces/CompensationGrid/ICompensationRate';
import { ICompensationScale } from 'interfaces/CompensationGrid/ICompensationScale';
import { IContractedHour } from 'interfaces/CompensationGrid/IContractedHour';

import { ContractEngagementType, ContractType } from 'types';
import ToastType from 'constants/enums/ToastType';
import VALIDATION_MESSAGES from 'constants/validationMessages';
import { selectStaffCategoryModalTitle } from 'constants/contractsConstants';
import { ICompensationGridDetails } from 'interfaces/CompensationGrid/ICompensationGridDetails';

const scale = 2;
const perPayrollMultiplier = 2;

export const calculatedContractedHoursValue = (contractedHour: IContractedHour, engagement: ContractEngagementType | '', fte: number) => {
  switch(engagement){
  case 'full_time':
    return `${contractedHour.hrs_per_week}/${contractedHour.hrs_per_week * perPayrollMultiplier}`;
  case 'part_time':
    return `${(contractedHour.hrs_per_week*fte).toFixed(scale)
      .replace(/[.,]00$/, '')}/${(contractedHour.hrs_per_week * perPayrollMultiplier*fte).toFixed(scale).replace(/[.,]00$/, '')}`;
  case 'per_diem':
    return contractedHour.title;
  default: return '';
  };
};

const calculatedWeeksOfServiceValue = (
  weeksOfSerice: number | '', contractType: ContractType | '', engagement: ContractEngagementType | ''
) => {
  if (contractType === 'pc' && engagement !== 'per_diem') {
    return weeksOfSerice;
  }
  return '';
};

const calculatedTimeOffValue = (timeOff: string, contractType: ContractType | '', engagement: ContractEngagementType | '') => {
  const timeOffMultiplier = 5;
  if (contractType === 'w2' && engagement !== 'per_diem') {
    const result = Number(timeOff) * timeOffMultiplier;
    return result === 0 || isNaN(result) ? '' : result.toString();
  }
  return '';
};

export const calculatedAnnualRateValue = (annualRate: string, engagement: ContractEngagementType | '', fte: number) => {
  switch(engagement){
  case 'full_time':
    return annualRate;
  case 'part_time':
    return String(Number(annualRate)*fte);
  default: return '';
  };
};

export const setFieldsValues = (
  values: IClinicianContractForm,
  contractedHour: IContractedHour,
  compensationScale: ICompensationScale,
  suitableRate: ICompensationRate,
  timeOff: string,
  setFieldValue: any,
  setSelectedContractedHour: any,
  setCompensationScale: any,
  setGridTermsApplied: any,
) => {
  setSelectedContractedHour(contractedHour);
  setFieldValue('payroll_details.contracted_hours',
    calculatedContractedHoursValue(
      contractedHour,
      values.payroll_details.engagement,
      values.payroll_details.fte as number,
    )
  );
  setFieldValue('payroll_details.weeks_of_service',
    calculatedWeeksOfServiceValue(
      compensationScale.weeks_of_service,
      values.contract_type,
      values.payroll_details.engagement,
    ));
  setFieldValue('payroll_details.notes', suitableRate.notes);
  setFieldValue('payroll_details.paid_time_off',
    calculatedTimeOffValue(
      timeOff,
      values.contract_type,
      values.payroll_details.engagement,
    ));
  if (values.payroll_details.engagement === 'per_diem') {
    setFieldValue('payroll_details.hourly_rate', suitableRate.hourly_rate);
  } else {
    setFieldValue('payroll_details.annual_rate',
      calculatedAnnualRateValue(
        suitableRate.annual_rate,
        values.payroll_details.engagement,
        values.payroll_details.fte as number
      )
    );
  }
  setCompensationScale(compensationScale);
  setFieldValue('compensation_rate_id', suitableRate.id);
  setGridTermsApplied(true);
};

export const applyGridTerms = (
  setFieldValue: any,
  values: IClinicianContractForm,
  compensationGrid: ICompensationGridDetails | undefined,
  onToastOpen: any,
  contractedHours: IContractedHour[],
  setSelectedContractedHour: any,
  setCompensationScale: any,
  setSuitableRate: any,
  setGridTermsApplied: any,
  setCompScalesForModal: any,
  onModalOpen: any
) => {
  if (compensationGrid?.compensation_scales.length === 1) {
    let suitableRate = undefined;
    const compensationScale = compensationGrid.compensation_scales[0];
    if(compensationScale.compensation_rates.length === 0) {
      return onToastOpen('error', VALIDATION_MESSAGES.NO_RATES_FOUND, ToastType.error);
    }
    const contractedHour = contractedHours.find((el: IContractedHour) => el.id === compensationScale.contracted_hour_id);
    if (compensationScale.rate_type === 'flat') {
      suitableRate = compensationScale.compensation_rates[0];
    } else {
      if (values.grad_date) {
        const yearsOfClinicianExperience = dayjs().diff(values.grad_date, 'year');
        suitableRate = findLast(compensationScale.compensation_rates,
          (rate) => yearsOfClinicianExperience >= Number(rate.experience));
        if (!suitableRate) {
          return onToastOpen('error', VALIDATION_MESSAGES.NO_SUITABLE_TIER_FOUND, ToastType.error);
        }
      } else {
        return onToastOpen('error', VALIDATION_MESSAGES.GRAD_DATE_NOT_SPECIFIED, ToastType.error);
      }
    };
    setSuitableRate(suitableRate);
    setFieldsValues(
      values,
      contractedHour as IContractedHour,
      compensationScale,
      suitableRate,
      compensationGrid.time_off,
      setFieldValue,
      setSelectedContractedHour,
      setCompensationScale,
      setGridTermsApplied
    );
  } else {
    let notEnoughExperienceError = false;
    let gradDateNotSpecifiedError = false;
    const yearsOfClinicianExperience = dayjs().diff(values.grad_date, 'year');
    const compScales = compact(compensationGrid?.compensation_scales.map((compensationScale) => {
      if (!(notEnoughExperienceError || gradDateNotSpecifiedError)) {
        if (compensationScale.compensation_rates.length === 0) {
          return undefined;
        };
        if (compensationScale.rate_type === 'tiered') {
          if (values.grad_date) {
            const rates = compact([findLast(compensationScale.compensation_rates,
              (rate) => yearsOfClinicianExperience >= Number(rate.experience))]);
            if (rates.length === 0) {
              notEnoughExperienceError = true;
            };
            return {
              ...compensationScale,
              compensation_rates: rates,
            };
          } else {
            gradDateNotSpecifiedError = true;
          }
        } else {
          return compensationScale;
        };
      };
      return undefined;
    }));
    const filteredCompScales = compScales.filter(scale => scale.compensation_rates.length > 0);
    if (filteredCompScales.length === 0 || notEnoughExperienceError || gradDateNotSpecifiedError) {
      if (notEnoughExperienceError) {
        return onToastOpen('error', VALIDATION_MESSAGES.NO_SUITABLE_TIER_FOUND, ToastType.error);
      }
      if (gradDateNotSpecifiedError) {
        return onToastOpen('error', VALIDATION_MESSAGES.GRAD_DATE_NOT_SPECIFIED, ToastType.error);
      }
      return onToastOpen('error', VALIDATION_MESSAGES.NO_RATES_FOUND, ToastType.error);
    } else {
      setCompScalesForModal(filteredCompScales);
      onModalOpen(selectStaffCategoryModalTitle);
    }
  };
};
