import { IClinicianContractForm } from 'interfaces/ClinicianContract/IClinicianContractForm';
import { colors } from 'styles/globalStyles';

export const initialMemo = "<p style='text-align: initial'></p>";

export const initialClinicianContractFormInformation: IClinicianContractForm = {
  clinician_id: '',
  facility_id: '',
  contract_type: '',
  compensation_grid_name: '',
  compensation_rate_id: '',
  payroll_memo: initialMemo,
  admin_memo: initialMemo,
  resign_date: '',
  last_day_of_service: '',
  contract_end_type: '',
  status: '',
  payroll_details: {
    engagement: '',
    fte: '',
    amendment_notes: '',
    execution_date: '',
    effective_date: '',
    contract_term: '',
    staff_category: '',
    contracted_hours: '',
    weeks_of_service: '',
    paid_time_off: '',
    annual_rate: '',
    hourly_rate: '',
    notes: '',
    contracted_frequency: '',
    payroll_frequency: '',
    malpractice_deduction: '',
  },
};

export const contractEngagementTypeOptions = [
  { id: 'full_time', name: 'Full-Time'},
  { id: 'part_time', name: 'Part-Time'},
  { id: 'per_diem', name: 'Per-Diem'},
];

export const statusOptions = [
  { id: 'Active', name: 'Active' },
  { id: 'Inactive', name: 'Inactive' },
  { id: 'In Progress', name: 'In Progress' },
];

export const contractStaffCategoryOptions = [
  { id: 'Admin', name: 'Admin' },
  { id: 'Regional Medical Director', name: 'Regional Medical Director' },
  { id: 'ARRP', name: 'ARRP' },
  { id: 'Cardiac', name: 'Cardiac' },
  { id: 'Cardiac Chief', name: 'Cardiac Chief' },
  { id: 'Cardiac/General', name: 'Cardiac/General' },
  { id: 'Chief CRNA', name: 'Chief CRNA' },
  { id: 'General', name: 'General' },
  { id: 'OB Service', name: 'OB Service' },
  { id: 'Medical Director', name: 'Medical Director' },
  { id: 'OB Chief', name: 'OB Chief' },
  { id: 'Pain Director', name: 'Pain Director' },
  { id: 'Vice Chief', name: 'Vice Chief' },
  { id: 'W2', name: 'W2' },
  { id: '1099/PC', name: '1099/PC' },
  { id: 'General - Call Taking', name: 'General - Call Taking' },
  { id: 'General - Non Call Taking', name: 'General - Non Call Taking' },
  { id: 'General - 2 Weeks on, 1 Week off', name: 'General - 2 Weeks on, 1 Week off' },
  { id: 'General - 1 Week on, 1 Week off', name: 'General - 1 Week on, 1 Week off' },
  { id: 'General - 3 Weeks on, 1 Week off', name: 'General - 3 Weeks on, 1 Week off' },
  { id: 'Trauma', name: 'Trauma' },
];

export const frequencyOptions = [
  { id: 'annually', name: 'Annually' },
  { id: 'bi-weekly', name: 'Bi-weekly' },
  { id: 'weekly', name: 'Weekly' },
  { id: 'daily', name: 'Daily' },
  { id: 'hourly', name: 'Hourly' },
];

export const contractEngagementToCompensationGridEngagementMapper = {
  full_time: 'full_time',
  part_time: 'full_time',
  per_diem: 'per_diem',
};

export const clinicianTitleToCompensationGridClinicianTitleMapper = {
  'CRNA': 'crna',
  'RN': 'rn',
  'NP': 'np',
  'MD': 'md_do',
  'DO': 'md_do',
  '': '',
};

export const frequencyMapper = {
  contracted_frequency: {
    full_time: {
      w2: 'annually',
      pc: 'weekly',
    },
    per_diem: {
      w2: 'hourly',
      pc: 'daily',
    },
  },
  payroll_frequency: {
    full_time: {
      w2: 'bi-weekly',
      pc: 'bi-weekly',
    },
    per_diem: {
      w2: 'hourly',
      pc: 'daily',
    },
  },
};

export const statusStylesMapper = {
  Active: {
    color: colors.secondaryGreen,
    border: '0px',
    backgroundColor: colors.lightGreen,
  },
  Inactive: {
    color: colors.textGray,
    border: `1px solid ${colors.disabledGray}`,
    backgroundColor: colors.white,
  },
  'In Progress': {
    color: colors.textYellow,
    border: '0px',
    backgroundColor: colors.statusYellow,
  },
};

export const contractTypeOptions = [
  { id: 'w2', name: 'W2' },
  { id: 'pc', name: 'PC' },
  { id: '1099', name: '1099'},
];

export const selectStaffCategoryModalTitle = 'Select Staff Category';

export const confirmModalTitle = 'Start Over?';
export const reportModalTitle = 'Creating Report';
